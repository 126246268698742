<template>
  <div class="min-h-full h-auto">
    <div class="bg-white px-10 py-4">
      <p class="text-xl font-bold">Legenda administratorskih dozvola</p>
    </div>
    <hr />
    <div class="w-full">
      <div class="flex flex-col w-full p-10 gap-5 h-full font-inter text-gray-900">
        <div
          class="
            flex flex-col
            gap-2
            p-5
            border-2 border-gray-200
            rounded-xl
            bg-white
            w-1/3
            shadow-lg
          "
          v-for="p in perms"
          :key="p.id"
        >
          <div class="flex flex-row gap-5 items-center justify-between">
            <p class="text-lg font-bold">{{ p.name }}</p>
            <p>
              ID: <strong> {{ p.id }}</strong>
            </p>
          </div>

          <hr />
          <p class="text-gray-600">{{ p.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { permissions } from "@/helpers/api.js";

export default {
  data() {
    return {
      perms: "",
    };
  },
  async mounted() {
    const res = await permissions();
    this.perms = res.data.permissions;
  },
};
</script>


<style>
</style>